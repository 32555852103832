<template>
  <div>
    <div class="topbar fixed is-flex is-flex-align-center">
      <div class="m-nav-content one-third ">
        <nav class="nav is-flex is-flex-align-center">
          <div id="toggle-menu">
            <input type="checkbox" name="" id="" />
              <span></span>
              <span></span>
              <span></span>

            <ul id="nav-menu">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/game">Leaderboard</router-link></li>
              <li><router-link to="/about">About</router-link></li>
              <li>
                <a href="https://showerspass.com">
                  <img class="logo-image" src="../assets/sp-logo.png" />
                </a>
              </li>
            </ul>

          </div>

        </nav>
      </div>

      <div class="sp-logo one-third text-center">
        <a href="https://showerspass.com">
          <img class="sp-logo-image" src="../assets/sp-logo.png" />
        </a>
      </div>
      <div class="shop shop-hover absolute is-flex is-flex-align-center">
        <a href="https://showerspass.com">Shop Showers Pass</a>
        <img src="../assets/icons/arrow-right.svg" alt="Right Arrow" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped>
.topbar {
  width: 100%;
  height: auto;
  padding: 1rem;
  background: var(--color-black);
  top: 0;
  z-index: 9999;
}

.nav {
  font-weight: 700;
  margin-left: 2rem;
}

#toggle-menu input {
  display: none;
}

#nav-menu {
  display: flex;

}

#nav-menu li {
  border-radius: 20px;
  padding: .5rem 2rem;
}

#nav-menu li:hover {

  background: var(--color-goldenrod);
}

#nav-menu li:last-child {
  display: none;
}

.shop {
  /* top: calc(50% / 1.4); */
  right: 3%;
  cursor: pointer;

}

.shop-hover {
  background-image: -webkit-linear-gradient(left, var(--color-black) 50%, var(--color-goldenrod) 50%);
  background-image: linear-gradient(to right, var(--color-black) 50%, var(--color-goldenrod) 50%);
  background-position: 0;
  background-size: 200%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 20px;
  padding: .5rem 2rem;
}
.shop-hover:hover {
  background-position: -100%;
}

.sp-logo-image {
  width: 30%;
  height: auto;
}


h1 {
  font-size: 2rem;
  color: var(--color-white);
}

a {
  text-decoration: none;
  color: var(--color-white);
  white-space: nowrap;
  margin-right: .5rem;
}

img {
  width: 20%;
  height: auto;
}

@media screen and (max-width: 1024px) {
  .topbar {
    justify-content: space-between;
  }

  .nav {
    margin-left: 0;
  }

  #toggle-menu {
    display: flex;
    flex-direction: column;
    /* position: relative;
    top: 25px;
    left: 25px; */
    z-index: 1;
    -webkit-user-select: none;
    user-select: none;
  }

  #toggle-menu input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
  }

  #toggle-menu span {
    display: flex;
    width: 29px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #FFFFFF;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 5px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                  opacity 0.55s ease;
  }

  #toggle-menu span:first-child {
    transform-origin: 0% 0%;
  }

  #toggle-menu span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  #toggle-menu input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: var(--color-goldenrod);
  }

  #toggle-menu input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #toggle-menu input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  #nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    height: 100vh;
    box-shadow: 0 0 10px #85888C;
    margin: -50px 0 0 -50px;
    padding: 50px;
    padding-top: 125px;
    background-color: var(--color-darkdenim);
    -webkit-font-smoothing: antialiased;
    transform-origin: 0% 0%;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  }

  #nav-menu li {
    padding: 1rem 0;
    font-size: 2rem;
    text-align: start;
    transition-delay: 2s;
  }

  #nav-menu li:last-child {
    display: block;
  }

  #toggle-menu input:checked ~ ul {
    transform: none;
  }

  .logo, .logo-image {
    width: 50%;
    image-rendering: -webkite-optimize-contrast;
  }

  .logo img {
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }

  /* .sp-logo {
    width: 100%;
  } */

  .sp-logo-image {
    width: 100%;
  }

  .shop a, .shop img {
    display: none;
  }

  .shop-hover {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .topbar {
    margin-left: 0;
  }

  .sp-logo {
    width: 40%;
  }

  .sp-logo-image {
    width: 100%;
  }

}
</style>
