import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Game from './views/Game.vue';
import About from './views/About.vue';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Home | Enjoy All Elements',
                metaTags: [
                    {
                        name: 'description',
                        content: 'The Enjoy All Elements campaign encourages spending more time outdoors in inclement weather'
                    },
                    {
                        property: 'og:description',
                        content: 'The Enjoy All Elements campaign encourages spending more time outdoors in inclement weather'
                      }
                ]
            }
        },
        {
            path: '/game',
            name: 'game',
            component: Game
        },
        {
            path: '/about',
            name: 'about',
            component: About
        }
    ],
    mode: 'history'
})