<template>
    <div class="footer is-flex is-flex-column is-flex-align-center is-flex-justify-center has-margin-top">
        <div class="socials has-margin-bottom">
            <h3 class="has-margin">Follow us on</h3>
            <div class="icons is-flex is-flex-space-between">
                <a href="https://facebook.com/showerspass"><b-icon icon="facebook" size="is-medium"></b-icon></a>
                <a href="https://instagram.com/showerspass"><b-icon icon="instagram" size="is-medium"></b-icon></a>
                <a href="https://youtube.com/showerspass"><b-icon icon="youtube" size="is-medium"></b-icon></a>
                <a href="https://twitter.com/showerspass"><b-icon icon="twitter" size="is-medium"></b-icon></a>
            </div>
        </div>
        <div class="nav is-flex has-margin">
            <router-link to="/"><b-button id="butn" type="is-primary">home</b-button></router-link>
            <router-link to="/game"><b-button id="butn" type="is-primary">leaderboard</b-button></router-link>
            <router-link to="/about"><b-button id="butn" type="is-primary">about</b-button></router-link>
        </div>
        <div class="contact has-margin-bottom">
            <h3 class="text-center">Questions? Reach us at <a mailto="support@showerspass.com">support@showerspass.com</a></h3>
        </div>
        <img src="../assets/icons/sp-logo-white.svg" alt="Showers Pass" />
    </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style scoped>
.footer {
    width: 100vw;
    background: var(--color-black);
    color: var(--color-white);
    padding: 2rem;
}

#butn {
    background: var(--color-goldenrod);
}

.nav * {
    margin: 0 .2rem;
}

h3 {
    font-weight: 800;
}

a {
    color: var(--color-goldenrod);
}

img {
    width: 100px;
}

</style>
