<template>
  <div class="leaderboard is-flex is-flex-column is-flex-align-center">
    <div class="section is-flex is-flex-align-center">
      <div class="section-left">
        <img src="../assets/images/artwork.jpg" alt="playing in the rain" loading="lazy" />
      </div>
      <div class="section-right">
        <h1 class="text-center">Leaderboard</h1>
        <p class="text-center">
          Check how you compare on our global leaderboard!
        </p>

        <div class="top-scorers is-flex animate__animated animate__zoomIn" v-if="data.length !== 0">
          <div class="panel is-flex is-flex-column">
            <div class="place-and-user">
              <span>2nd Place</span>
              <h1>{{ data[1].nickname }}</h1>
            </div>
            <div class="total-hours text-center">
              {{ data[1].hours }}
            </div>
          </div>
          <div class="panel is-flex is-flex-column is-flex-justify-center">
            <div class="place-and-user">
              <span>Currently Leading</span>
              <h1>{{ data[0].nickname }}</h1>
            </div>
            <div class="first text-center">
              {{ data[0].hours }}
            </div>
          </div>
          <div class="panel is-flex is-flex-column">
            <div class="place-and-user">
              <span>3rd Place</span>
              <h1>{{ data[2].nickname }}</h1>
            </div>
            <div class="total-hours text-center">
              {{ data[2].hours }}
            </div>
          </div>
        </div>
        <div class="top-scorers is-flex" v-else>
          <div class="panel is-flex is-flex-column">
            <div class="place-and-user">
              <span>2nd Place</span>
              <h1>None</h1>
            </div>
            <div class="total-hours text-center">
              --
            </div>
          </div>
          <div class="panel is-flex is-flex-column">
            <div class="place-and-user">
              <span>Currently Leading</span>
              <h1>None</h1>
            </div>
            <div class="first text-center">
              --
            </div>
          </div>
          <div class="panel is-flex is-flex-column">
            <div class="place-and-user">
              <span>3rd Place</span>
              <h1>None</h1>
            </div>
            <div class="total-hours text-center">
              --
            </div>
          </div>
        </div>

        <div class="has-margin">
          <b-table
            :data="data"
            :columns="columns"
            hoverable
            :paginated="paginated"
            per-page="8"
          ></b-table>
        </div>
      </div>
    </div>
    <div class="stat">
      <MissionStats />
    </div>

    <Media />
  </div>
</template>

<script>
import * as GSheetsReader from 'g-sheets-api';
import MissionStats from "./MissionStats";
import Media from './Media';

const options = {
  apiKey: 'AIzaSyCMlxr1mVW3Kmrs8AUEKJIkYrYGW3ShjT0',
  sheetId: '1CvpsElb6yxqVfP2TDQtrrjb69HjFI2rUTfHFtFfpIN0',
  sheetNumber: 2,
  sheetName: 'Leaderboard',
  returnAllResults: true,
}

export default {
  name: "Leaderboard",
  components: {
    MissionStats,
    Media
  },
  data() {
    const data = [];

    return {
      data,
      nickname: '',
      hours: 0,
      columns: [
        {
          field: "rank",
          label: "Rank",
          width: "100",
          numeric: true,
          centered: true,
        },
        {
          field: "nickname",
          label: "Nickname",

          searchable: true,
        },
        {
          field: "hours",
          label: "Total Hours",
          numeric: true,
          centered: true,
        },
      ],
      paginated: true,
    };
  },

  created () {
    this.getSheetData()
  },

  methods: {
    isLoading() {
      setTimeout(function () {
        console.log("done");
      }, 2000);
    },

    getSheetData() {
      GSheetsReader(
        options,
        results => {
          for (let i = 0; i < results.length; i++) {
            this.data.push({
              rank: Number(results[i].rank),
              nickname: results[i].username,
              hours: Number(results[i].hours)
            })
          }
        }
      ).catch(err => {
        console.warn(err);
        this.error();
      })
    },

    error() {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Failed to fetch data. Please refresh the page.`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  },
};
</script>

<style>
.b-table tbody {
  padding: 5rem;
}

tbody {
  padding: 2rem 0.75rem;
}

.table td, .table th {
  padding: 1rem 0.75rem !important;
}

.table span {
  font-weight: 700;
}
</style>

<style scoped>
.leaderboard {
  margin-top: -12rem;
}

.stat {
  width: 100%;
}

.section {
  width: inherit;
  height: 100%;
}

.section-left {
  flex: 1 0 40%;
  height: 100%;
}

.section-right {
  flex: 1 0 60%;
  box-shadow: 0px 1px 2px 2px rgba(231, 231, 231, 0.774);
  -webkit-box-shadow: 0px 1px 9px 2px rgba(181, 181, 181, 0.5);
  -moz-box-shadow: 0px 1px 2px 2px rgba(181, 181, 181, 0.5);
  padding: 1rem;
  border-left: 10px solid #acacac;
  background: var(--color-white);
}

.section-right p {
  margin: .5rem 0 1.5rem;
}

.section-left img {
  max-width: 100%;
  max-height: auto;
}

h1 {
  font-size: 3rem;
  font-weight: 800;
}

.panel {
  width: calc(100% / 3);
  height: auto;
  padding: 1rem;
  margin: 0 .5rem;
  background: var(--color-goldenrod);
  color: var(--color-darkdenim);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.panel:nth-child(odd) {
  margin-top: 4rem;
  margin-bottom: 0;
  background: var(--color-darkdenim);
  color: var(--color-white);
}

.place-and-user {
  padding: 1rem;
  border-bottom: 1px solid #f1f2f5;
  text-align: center;
}

.place-and-user span {
  font-weight: 700;
  /* opacity: .9; */
  padding: .5rem;
  background: var(--color-white);
  color: var(--color-black);
  border: 2px solid var(--color-white);
  border-radius: 20px;
}

.place-and-user h1 {
  font-size: clamp(1rem, 2rem, 1.5rem);
  text-align: center;
  margin-top: 1.5rem;
}

.total-hours, .first {
  padding: 1rem;
  font-size: 5rem;
  font-weight: 500;
  color: var(--color-goldenrod);
}

.first {
  color: var(--color-darkdenim);
}

.total-hours::after, .first::after {
  content: 'hrs';
  display: inline;
  font-size: 1.5rem;
  margin-left: -20px;
}

.pagination-link .is-current {
  color: var(--color-goldenrod);
}

@media screen and (max-width: 1650px) {
  .top-scorers {
    width: 100%;
    flex-direction: column;
  }

  .panel {
    width: 80%;
    padding: .5rem;
    margin: 1rem auto;
  }

  .panel:nth-child(odd) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .section {
    flex-direction: column-reverse;
  }

  .section-right {
    width: 100%;
    border-left: none;
  }
}


@media screen and (max-width: 798px) {
  .section {
    flex-direction: column-reverse;
  }

  .section-left {
    margin-top: 2rem;
  }

  .section-left img {
    max-width: 100%;
  }

  .section-right {
    border-left: none;
  }
}

@media screen and (max-width: 480px) {
  .panel {
    width: 100%;
  }
}
</style>
