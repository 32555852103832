<template>
  <div class="home">
      <Landing />
    </div>  
</template>

<script>
import Landing from '../components/Landing';

export default {
    name: 'Home',
    title: 'Enjoy All Elements',
    components: {
        Landing
    },
    mounted () {
        window.scrollTo(0, 0);
    }
}
</script>

<style>
</style>