.<template>
  <div class="about">
    <div class="about-content">
      <h1 class="text-center">What Is Enjoy All Elements?</h1>
      <p>
        It’s a collective, community effort, led by Showers Pass, to get more people outside more often. 
        We know first-hand that the more time people spend outside, the happier they are. Studies have shown 
        that time outside correlates a deeper connection and appreciation for our planet with general well-being. 
        We also know that not everyone has access to enjoy the outdoors, whether the obstacle is inexperience or 
        inclement weather. Showers Pass exists to remove obstacles to enjoying the outdoors, from our gear to our 
        advocacy efforts; and it’s why we're challenging everyone to help us get to 175 million hours outside by 2031.
      </p>
      <br>
      <p>
        Enjoy All Elements challenges participants to enjoy the outdoors – whatever the weather. For every hour spent 
        outdoors, Showers Pass is committed to donating resources to the Outdoors Empowered Network, a national network 
        of community-led, youth-centered outdoor education groups and gear libraries. We’re making it very easy to get 
        involved with 3 ways to have your hours counted: 
      </p>
      
      <section class="ways-to-contribute is-flex has-margin-bottom animate__animated animate__pulse">
        <div class="ways-to-contribute__box is-flex is-flex-column is-flex-justify-center is-flex-align-center">
          <div class="icon has-margin">
            <img src="../assets/icons/hours.svg" alt="clock icon" />
          </div>
          <h3 class="text-center">Log Hours</h3>
          <div class="popup-info">
            <p>Use the challenge hub (this website) to log your hours, and compete with friends on our leaderboard!</p>
          </div>
        </div>
        <div class="ways-to-contribute__box is-flex is-flex-column is-flex-justify-center is-flex-align-center">
          <div class="icon has-margin">
            <img src="../assets/icons/tag.svg" alt="socials icon" />
          </div>
          <h3 class="text-center">Hashtag</h3>
          <div class="popup-info">
            <p>Use the hashtag <span>#enjoyallelements</span> on Instagram and show off your fun in the rain (or snow, sun, or wind, etc).</p>
          </div>
        </div>
        <div class="ways-to-contribute__box is-flex is-flex-column is-flex-justify-center is-flex-align-center">
          <div class="icon has-margin">
            <img src="../assets/icons/purchase.svg" alt="shop icon" />
          </div>
          <h3 class="text-center">Purchase</h3>
          <div class="popup-info">
            <p>Make any purchase on showerspass.com. We know our gear helps you get out more, so we'll estimate hours based on your purchase.</p>
          </div>
        </div>
      </section>

      <p>
        With your help, we can ensure that the benefits of nature are accessible to everyone. With both gear and monetary 
        support generated based on your hours logged in the challenge, we'll be giving to organizations dedicated to improving 
        access to the outdoors.
      </p>
      <div class="gallery">
        <div class="is-flex has-margin-top rs">
          <img src="../assets/images/about-goldenrod-sp.jpg" alt="Showers Pass Goldenrod Jacket" />
          <img src="../assets/images/about-gear-library.jpg" alt="Showers Pass Gear Library" />
        </div>
      </div>

      <div class="stat">
        <MissionStats />
      </div>
    </div>
    <div class="faqs has-margin-top">
      <h1 class="text-center">Frequently Asked <span>Questions</span></h1>
        <div class="faq-section is-flex is-flex-column">
          <div class="faq-block has-margin">
            <div class="faq-question is-flex is-flex-align-center has-margin-bottom">
              <div class="avatar is-flex is-flex-column is-flex-justify-center is-flex-align-center">
                <b-icon icon="help" size="is-medium"></b-icon>  
              </div>

              <div class="question text-center">
                <h3>How do I log my hours?</h3>
              </div>
            </div>
            <div class="faq-answer is-flex is-flex-align-center">
              <div class="answer">
                <p>Log hours right here on <a href="/">enjoyallelements.com</a>! Visit this section and fill out the form: email, nickname, hours, and whether or not you want to get reminders to log your hours by email. Use the same email address each time you log to see your hours increase and climb the leaderboard!</p>
              </div>
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="face-agent" size="is-medium"></b-icon>                
              </div>
            </div>
          </div>
          <div class="faq-block has-margin">
            <div class="faq-question is-flex is-flex-align-center has-margin-bottom">
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="help" size="is-medium"></b-icon>                
              </div>
              <div class="question text-center">
                <h3>Who is the Outdoor Empowerment Network?</h3>
              </div>
            </div>
            <div class="faq-answer is-flex is-flex-align-center">
              <div class="answer">
                <p>OEN is a national network of community-led, youth-centered outdoor education groups that are dedicated to increasing access and diversity in the outdoors through gear libraries and outdoor leadership training. These tools dramatically decrease the barriers to outdoor recreation for low-income communities and communities of color. </p>
              </div>
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="face-agent" size="is-medium"></b-icon>                
              </div>
            </div>
          </div>
          <div class="faq-block has-margin">
            <div class="faq-question is-flex is-flex-align-center has-margin-bottom">
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="help" size="is-medium"></b-icon>                
              </div>
              <div class="question text-center">
                <h3>How can I find out how much my time outside has contributed to OEN?</h3>
              </div>
            </div>
            <div class="faq-answer is-flex is-flex-align-center">
              <div class="answer">
                <p>Check the leaderboard to see how many hours you’ve contributed, or take a look at the running total for hours and total value donated! Since there are multiple ways to contribute hours, we’re combining multiple methodologies behind-the-scenes to add it all up. In the future, we plan to build a more sophisticated system integrated with wearable fitness trackers and services you already use, so stay tuned!</p>
              </div>
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="face-agent" size="is-medium"></b-icon>                
              </div>
            </div>
          </div>
          <div class="faq-block has-margin">
            <div class="faq-question is-flex is-flex-align-center has-margin-bottom">
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="help" size="is-medium"></b-icon>                
              </div>
              <div class="question text-center">
                <h3>What kind of gear have you donated to OEN?</h3>
              </div>
            </div>
            <div class="faq-answer is-flex is-flex-align-center">
              <div class="answer">
                <p>Showers Pass will be focusing on donating gear that is most needed, by request of the gear library, including rain jackets and pants.</p>
              </div>
              <div class="avatar is-flex is-flex-justify-center is-flex-align-center">
                <b-icon icon="face-agent" size="is-medium"></b-icon>                
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import MissionStats from "../components/MissionStats";

export default {
    name: 'About',
    title: 'Enjoy All Elements - About',
    components: {
      MissionStats,
    },
    mounted () {
      window.scrollTo(0, 0);
    }
}
</script>

<style>

.about {
  margin-top: 10rem;
}

.about-content {
  width: 80%;
  margin: 0 auto;
  background: var(--color-white);
}

.ways-to-contribute {
  width: 100%;
  height: auto;
  padding: 5rem;
}

.ways-to-contribute .icon {
  width: 30%;
  height: auto;
}

.ways-to-contribute__box {
  width: 100%;
  height: 100%;
  margin: 0 1rem;
  padding: 1rem 0 0 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / .2);
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
}

.ways-to-contribute__box:hover .popup-info {
  width: 100%;
  height: auto;
  padding: 1rem;
  bottom: 0;
  opacity: 1;
}

.ways-to-contribute__box h3 {
  
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: black;
}

.popup-info {
  width: 100%;
  height: 0;
  padding: 0;
  opacity: 0;
  position: relative;
  bottom: 120px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: var(--color-darkdenim);
  color: var(--color-white);
  transition: all .2s ease;
}

.ways-to-contribute__box span {
  color: var(--color-goldenrod);
}

.gallery {
  width: 100%;
}

.gallery img {
  width: 50%;
}

.faqs {
  width: 80%;
  margin: 0 auto;
  background: var(--color-white);
}

.about-content h1, .faqs h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
}

.about-content p, .faqs p {
  font-size: 1.2rem;
  line-height: 1.2;
}

.faqs h3 {
  font-size: 1.5rem;
  font-weight: 700;
}

.faqs span {
  color: var(--color-goldenrod);
}

.faq-section {
  width: 70%;
  margin: 0 auto;
}

.avatar {
  width: 50px;
  height: 50px;
  margin-right: .5rem;
  margin-left: .5rem;
  border-radius: 50%;
  /* background: #f8f9fa; */
}

.question, .answer {
  padding: 1.5rem 1rem;
  border-radius: 15px;
}

.question {
  width: 100%;
  background: #f8f9fa;
}

.answer {
  background: var(--color-goldenrod);
}

small {
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  .gallery {
    width: 100%;
  }
  .stat {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .ways-to-contribute {
    flex-direction: column;
  }

  .ways-to-contribute__box {
    margin: 1rem 0;
  }

  .popup-info {
    width: 100%;
    height: 100%;
    padding: 1rem;
    bottom: 0;
    opacity: 1;
  }

  .ways-to-contribute__box {
    width: 100%;
  }
  
  .faq-section {
    flex-direction: column;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .ways-to-contribute {
    padding: 1rem;
  }

  .rs {
    flex-direction: column;
  }

  .gallery img {
    width: 100%;
  }

  .about-content {
    width: 100%;
    padding-left: 1rem;
    padding: 1rem;
    text-align: center;
  }
}

</style>