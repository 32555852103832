<template>
  <div class="stats is-flex">
    <div class="stat-hours is-flex is-flex-column is-flex-justify-center text-center has-padding">
      <h2><span>{{ total_hours }}</span> hours</h2>
      <p>spent outside in all kinds of weather</p>
    </div>
    <div class="stat-donated text-center has-padding">
      <h2><span>${{ total_donated }}</span> donated</h2>
      <p>in gear to the Outdoors Empowered Network of organizations</p>
    </div>
  </div>
</template>

<script>
import * as GSheetsReader from 'g-sheets-api';

const options = {
  apiKey: 'AIzaSyCMlxr1mVW3Kmrs8AUEKJIkYrYGW3ShjT0',
  sheetId: '1CvpsElb6yxqVfP2TDQtrrjb69HjFI2rUTfHFtFfpIN0',
  sheetNumber: 3,
  sheetName: 'Total Contributions',
  returnAllResults: true,
}

export default {
  name: "MissionStats",
  data() {
    const total_hours = 0;
    const total_donated = 0;

    return {
      total_hours,
      total_donated
    }
  },
  created () {
    this.$nextTick(function() {
      this.getSheetData();

    })
  },
  methods: {
    getSheetData() {
      GSheetsReader(
        options,
        results => {
          this.total_hours = results[0].total_hours;
          this.total_donated = results[0].total_donated;
        }
      ).catch(err => {
        console.warn(err);
        this.error();
      })
    },

    error() {
      this.$buefy.toast.open({
        duration: 10000,
        message: `Failed to fetch data. Please refresh the page.`,
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }
};
</script>

<style scoped>
.stats {
  width: 100%;
  height: auto;
  bottom: 0;
}

span {
  color: var(--color-goldenrod);
}

.stat-hours {
  flex: 1 0 50%;
  background: var(--color-darkdenim);
  color: var(--color-white);
}

.stat-donated {
  flex: 1 0 50%;
  background: var(--color-black);
  color: var(--color-white);
}

.stat-hours > h2,
.stat-donated > h2 {
  font-size: clamp(2rem, 3.5rem, 4rem);
  text-transform: uppercase;
}

@media screen and (max-width: 1024px){
  .stats {
    flex-direction: column;
    width: 100%;
    position: static;
  }

  .stat-donated {
    margin-left: 0;
  }

.stat-hours > h2,
.stat-donated > h2 {
    /* font-size: 3rem; */
  }
}
</style>