.<template>
  <section class="media-gallery is-flex is-flex-wrap">
      <div class="media-box">
          <img src="../assets/images/leaderboard/placeholder-1.jpg" alt="" loading="lazy" >
      </div>
      <div class="media-box">
          <img src="../assets/images/leaderboard/placeholder-2.jpg" alt="" loading="lazy" >
      </div>
      <div class="media-box">
          <img src="../assets/images/leaderboard/placeholder-3.jpg" alt="" loading="lazy" >
      </div>
      <div class="media-box">
          <img src="../assets/images/leaderboard/placeholder-4.jpg" alt="" loading="lazy" >
      </div>
  </section>
</template>

<script>
export default {
    name: 'Media'
}
</script>

<style>
.media-box {
    width: calc(100% / 4);
    height: auto;
    background-size: cover;
    transition: all .2s ease-in-out;
}

.media-box:hover {
    scale: 1.1;
}

@media screen and (max-width: 1280px) {
    .media-box {
        width: calc(100% / 2);
    }
}
</style>
