<template>
    <b-carousel :autoplay="true" :indicator="false" :indicator-inside="true" @click="switchGallery(true)">
        <b-carousel-item v-for="(item, i) in items" :key="i">
            <div class="contain">
                <a class="image">
                    <img :src="item.source" loading="lazy">
                </a>
            </div>
        </b-carousel-item>
        <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
    </b-carousel>
</template>

<script>
export default {
    name: "Carousel",
    data() {
        return {
            gallery: true,
            items: [
                {
                    source: require('../assets/images/slideshow/eae-slide-1.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-2.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-4.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-5.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-6.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-7.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-8.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-9.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-10.jpg'),
                },
                {
                    source: require('../assets/images/slideshow/eae-slide-11.jpg'),
                }
            ]
        }
    },
    methods: {
        getImgUrl(value) {
            value += 50
            return `https://picsum.photos/id/10${value}/1230/500`
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    }
}
</script>

<style>
/* .is-active .al img {
    border: 1px solid #fff;
    filter: grayscale(0%);
}
.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
} */

.carousel-item {
    width: 100%;
    pointer-events: none;
}

.contain {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.image img {
    width: 100%;
    height: auto;
    /* margin: 0 auto; */
    object-fit: cover;
}

@media screen and (max-width: 798px) {
    .image img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .contain {
        width: 100%;
    }
}
</style>
