<template>
  <div id="app">
    <TopBar />
    <div class="tango">
      <!-- <div id="nav">
        <router-link to="/"><b-button id="butn" type="is-primary" icon-left="home">Home</b-button></router-link>
        <router-link to="/game"><b-button id="butn" type="is-primary" icon-left="medal">Leaderboard</b-button>
        </router-link>
        <router-link to="/about"><b-button id="butn" type="is-primary" icon-left="information">About</b-button></router-link> -->
        <!-- <router-link class="l" to="/"><b-icon icon="home" size="is-small">Campaign</b-icon></router-link>
        <router-link class="l" to="/game"><b-icon icon="trophy" size="is-small">Leaderboard</b-icon></router-link> -->
      <!-- </div>
      <div class="backdrop relative">
        <img src="./assets/icons/Cloudy.svg" alt="clouds" />
      </div> -->
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import TopBar from "./components/TopBar";
import Footer from './components/Footer';

export default {
  name: "App",
  components: {
    TopBar,
    Footer
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--font-primary) !important;
  font-size: var(--font-size-base);
  color: var(--color-black);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.025em;
  margin: 0 0 10px;
  font-weight: 800;
}

h1,
h2,
h3 {
  font-weight: 600;
}

p {
  margin: 0 0 10px;
  letter-spacing: 0.025rem;
  line-height: 1.5;
}

ol,
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  display: inline-block;
  text-decoration: none;
  color: var(--color-black);
  cursor: pointer;
}
img {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  image-rendering: -webkite-optimize-contrast;

}
svg {
  fill: currentColor;
  vertical-align: middle;
}
input {
  border-radius: 0;
  outline: 0;
}

button {
  display: inline-block;
  color: var(--color-goldenrod);
  font-family: var(--font-primary);
  font-size: var(--font-size-base);
  font-weight: 700;
  border-radius: 50px;
  border: 0;
  padding: 10px 20px;
  cursor: pointer;
  /* &:hover,
  &:focus {
    color: ${colors.white};
    outline: 0;
  } */
}

#butn {
  background: var(--color-goldenrod);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

#butn:active {
  background: var(--color-white);
  color: var(--color-black);
}

#nav {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 2rem;
}

#nav * {
  margin: 0 .2rem;
}

.router-link-exact-active {
  /* background: #ffd900; */
  /* padding-bottom: .5rem;
  border-bottom: 12px dotted var(--color-goldenrod) !important; */
}

.l {
  padding: .5rem 1.5rem;
  margin: 0 .6rem;
  background: var(--color-goldenrod);
  box-shadow: 0px 1px 1px 1px rgba(231, 231, 231, 0.4);
  -webkit-box-shadow: 0px 1px 1px 1px rgba(231, 231, 231, 0.4);
  -moz-box-shadow: 0px 1px 1px 1px rgba(231, 231, 231, 0.4);
  border-radius: 5px;
  color: var(--color-white);
}

.tango {

}

#app {
  width: 100%;
  margin: 0 auto;
}

/* .backdrop {
  width: 100vw;
  height: 100%;
  z-index: -1;
  margin: -20rem 0 10rem -3rem;
} */

@media screen and (max-width: 480px) {
  #app {
    width: 100%;

  }

  #nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: 0 auto;
  }

  #nav #butn {
    margin: .4rem 0;
  }

  .backdrop {
    margin: 2rem 0;
  }

  .router-link-exact-active {
    padding: 0;
  }
}
</style>
