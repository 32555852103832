import Vue from "vue";
import App from "./App.vue";
import router from './router';

import titleMixins from './mixins/titleMixins';

import 'animate.css';

import { Table, Input, Field, Button, Icon, Carousel, Modal, Toast, Steps, Checkbox } from "buefy";
import "buefy/dist/buefy.css";

import "./assets/styles/global.css";
import "./assets/styles/reset.css";
import "./assets/styles/variables.css";
import "./assets/styles/helpers.css";

// Mixins
Vue.mixin(titleMixins);

// Vue.use(VueRouter);
Vue.use(Table);
Vue.use(Input);
Vue.use(Field);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Carousel);
Vue.use(Modal);
Vue.use(Toast);
Vue.use(Steps);
Vue.use(Checkbox);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router
}).$mount("#app");

