<template>
  <div class="landing has-margin-top">
    <div class="hero-gallery relative is-flex is-flex-no-wrap">

      <div class="callout absolute has-margin is-flex is-flex-column is-flex-align-center">
        <div class="eae-logo has-margin-bottom animate__animated animate__backInDown">
          <img src="../assets/eae-vector.svg" alt="Enjoy all elements logo" />
        </div>
        <div class="animate__animated animate__fadeInUp">
          <b-button id="butn" type="is-primary" @click="goto('submission')">Join In</b-button>
        </div>
      </div>
      <img class="scale" src="../assets/images/eae-banner.jpg" alt="Hero Image 1" />
      <div class="stat absolute">
        <MissionStats />
      </div>
    </div>

    <div class="info-grid is-flex has-margin-top has-margin-bottom">
      <div class="info-grid__card is-flex is-flex-column">
        <div class="mobile-only__display has-margin-bottom">
          <h3 class="text-center">Good Times In Bad Weather</h3>
        </div>

        <div class="info-grid__image">
          <img src="../assets/images/eae-uk-3.jpg" alt="Showers Pass Waterproof Jacket" loading="lazy" />
        </div>
        <div class="info-grid__text is-flex is-flex-column">
          <h3 class="text-center">Good Times In Bad Weather</h3>
          <p>
            Studies have shown more time outside makes you happier. So don’t let the weather get in the way.
            Find happiness - whatever the weather - where you least expect it.
          </p>
        </div>
      </div>
      <div class="info-grid__card is-flex is-flex-column">
        <div class="mobile-only__display has-margin-bottom">
          <h3 class="text-center">Challenge Yourself And Your Friends</h3>
        </div>

        <div class="info-grid__image">
          <img src="../assets/images/eae-13.jpg" alt="Showers Pass Waterproof Jacket" loading="lazy" />
        </div>
        <div class="info-grid__text is-flex is-flex-column">
          <h3 class="text-center">Challenge Yourself And Your Friends</h3>
          <p>
            Enjoy All Elements challenges your idea of what’s possible outside - whatever the weather.
            How much time can you spend outside? Log your hours to keep track of your progress, compete
            with friends, and set personal records.
          </p>
        </div>
      </div>
      <div class="info-grid__card is-flex is-flex-column">
        <div class="mobile-only__display has-margin-bottom">
          <h3 class="text-center">More Hours = Bigger Donations</h3>
        </div>

        <div class="info-grid__image">
          <img src="../assets/images/about-gear-library.jpg" alt="Girls playing in the rain" loading="lazy" />
        </div>
        <div class="info-grid__text is-flex is-flex-column">
          <h3 class="text-center">More Hours = Bigger Donations</h3>
          <p>
            We’re turning your contributed hours outside into support for a national network of gear libraries
            and training programs to get even more people outside. Take the challenge, enjoy the elements, and
            make a positive impact in communities across the country.
          </p>
        </div>
      </div>
    </div>

    <div class="standout is-flex is-flex-column is-flex-align-center has-margin-top has-margin-bottom">
      <div class="backdrop">
        <img src="../assets/icons/Cloudy.svg" alt="clouds" loading="lazy"/>
      </div>
      <div class="standout-text relative text-center">
        <h1 class="text-center has-margin-bottom">
          Our goal is to get 175 million hours spent outside in ALL kinds of weather so
          more people have access to the outdoors
        </h1>
        <b-button id="butn" type="is-primary" @click="goto('submission')">Join In</b-button>
      </div>
    </div>

    <div
      class="info-1 is-flex is-flex-align-center"
    >
      <div class="text has-padding-top">
        <h3>
          How many hours can you spend outside? Challenge yourself, log your hours, and improve access
          to the outdoors
        </h3>
        <p>
          Developing solutions to make the outdoors more enjoyable is our mission at Showers Pass.
          Because we believe more time spent in nature means more human happiness. That’s why we’re committed
          to accumulating 175 million hours outdoors in all types of weather. By participating in the Enjoy All Elements
          challenge, you can set personal records, compete with friends, and feel good about the fact that your time
          outside translates to helping others do the same.
        </p>

        <div
          class="selections is-flex has-margin-top"
        >
            <b-button id="butn" type="is-primary" @click="goto('submission')">Join In</b-button>
            <b-button id="butn" type="is-primary" @click="copyToClipboard">Share This Campaign</b-button>
            <b-button id="butn" type="is-primary" @click="goto('info')">Learn More</b-button>
        </div>
      </div>

      <b-modal v-model="isImageModalActive">
        <iframe src="https://www.youtube.com/embed/ivIKQaNHQeE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </b-modal>

      <div class="s-img">
        <Carousel />
      </div>
    </div>

    <div class="info-2 is-flex" ref="info">
      <div class="info-2__first">
        <h3 class="text-center">3 Ways to Log Your Hours</h3>
        <div class="is-flex is-flex-row is-flex-space-around has-margin">
          <img src="../assets/icons/hours.svg" alt="clock icon" loading="lazy" />
          <img src="../assets/icons/tag.svg" alt="socials icon" loading="lazy" />
          <img src="../assets/icons/purchase.svg" alt="shop icon" loading="lazy" />
        </div>

        <div class="steps is-flex is-flex-column is-flex-align-center text-center">
          <p class="has-margin">
            How does it work? You can contribute your hours outside 3 different
            ways:
          </p>
          <ul>
            <li>1) Log your hours outside on this page</li>
            <li>2) Tag a photo of your outdoor adventure <span>#enjoyallelements</span> on Instagram</li>
            <li>3) Make a purchase at <span>showerspass.com</span></li>
          </ul>
        </div>
      </div>

      <div class="info-2__second">
        <h3 class="text-center">Our Partners</h3>
        <div class="content" style="margin:1rem;">
          <div class="g is-flex is-flex-space-evenly is-flex-align-center has-margin">
            <div class="image-container" style="width:40rem;">
              <img src="../assets/oen-logo.png" alt="Outdoors Empowered Network" loading="lazy"/>
            </div>
            <div>
              <a href="https://outdoorsempowered.org"><span>Outdoors Empowered Network</span></a>
              <p class="text-justify">
                As our main partner, Outdoor Empowerment Network is working to increase equitable access to nature.
                By seeding and supporting gear libraries around the country, as well as providing mentoring and
                consulting for youth, OEN succeeds in supporting access to nature for thousands of young people each year.
              </p>
              <b-button id="butn" type="is-primary" @click="isImageModalActive = true">OEN Promo Video</b-button>
            </div>

          </div>
          <div class="g is-flex is-flex-reverse is-flex-space-evenly is-flex-align-center is-flex-justify-center has-margin">
            <div class="image-container">
              <img src="../assets/wildseed-logo.png" alt="Maine Gear Share" loading="lazy"/>
            </div>
            <div>
              <a href="https://www.outdoorsempowered.org/"><span>Wildseed Gear Library</span></a>
              <p class="text-justify">
                Our mission is to make nature accessible for all individuals &amp; families, especially those of color, by lending high-quality camping &amp; outdoor gear, sharing educational resources, and planning camping & other day trips.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="banner" ref="submission">
      <h1 class="text-center">Join the Challenge!</h1>
    </div>

    <div class="info-3 is-flex is-flex-align-center is-flex-justify-center has-margin-top has-margin-bottom">
      <div class="info-3__left">
        <img
          src="../assets/images/eae-12.jpg"
          alt="Showers Pass Waterproof Jacket"
          loading="lazy"
        />
      </div>
      <div class="info-3__right text has-margin">
        <h3 class="text-center">
          Join the challenge, log your hours, and start supporting a national effort to increase access to nature.
        </h3>
        <p class="text-center">
          Make a difference in someone’s life, one adventure at a time
          <br>
          <span>Make sure to use the same email to boost your total hours</span>
        </p>

        <div class="selection is-flex is-flex-justify-center has-margin-top">
            <div class="has-margin-top has-margin-bottom">
                <!-- <div class="is-flex has-margin-bottom is-flex-justify-center is-flex-align-center">
                    <img src="../assets/icons/hours.svg" alt="clock icon" />
                    <h3>Log Your Hours!</h3>
                </div> -->
                <Submission />
            </div>

          <!-- <a class="has-margin">View Leaderboard</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MissionStats from "./MissionStats";
import Submission from "./Submission";
import Carousel from "./Carousel";

export default {
  name: "Landing",
  components: {
    MissionStats,
    Submission,
    Carousel,
  },

  data() {
    return {
      isImageModalActive: false,
    };
  },
  mounted() {
    // let data = "some data";
    // console.log(data);
  },
  methods: {
    goto(refName) {
      let element = this.$refs[refName];
      console.log(element);
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },

    copyToClipboard() {
      navigator.clipboard.writeText(window.location.href);

      this.$buefy.toast.open({
        duration: 5000,
        message: `Link copied!`,
        position: 'is-bottom',
        type: 'is-success'
      })
    }
  }
};
</script>

<style scoped>
h1, h2, h3 {
  font-weight: 600;
}

p {
  line-height: 1.2;
}

span {
  font-weight: 700;
  color: var(--color-goldenrod);
}

iframe {
  width: 100% !important;
  height: 100vh !important;
}

#butn:first-child {
  background: var(--color-goldenrod);
  color: var(--color-white)
}

#butn {
  /* background: none; */
  color: var(--color-black);
}

#butn:active {
  background: var(--color-darkdenim);
  color: white;
}

img {
  max-width: 100%;
  height: auto;
}

.hero-gallery {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.eae-logo {
  max-width: 100%;
}

.callout {
  width: 30%;
  left: 10rem;
  top: 10rem;
}

.callout > h1 {
  z-index: 9999;
  font-size: var(--font-size-xxl);
  color: var(--color-white);
}

.info-grid__card {
  width: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.info-grid__image {
  width: 100%;
  height: 550px;
  margin-bottom: 1rem;
}

.info-grid__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-grid__text h3 {
  font-size: var(--font-size-lg);
  color: var(--color-black);
  margin-bottom: 1rem;
}

.info-grid__text p {
  margin: 0 1rem;
  text-align: start;
  color: var(--color-black);
  line-height: 1.5;
}

.backdrop {
  width: 100vw;
  margin-top: 5rem;
  transform: rotate(180deg);
  opacity: .6;
  z-index: -1;
}

.standout {
  background-image: url('../assets/icons/rain.svg');
}

.standout-text {
  width: 60%;
  top: -300px;
  background: rgba(255, 255, 255, .8);
}

.stat {
  width: 100%;
  bottom: 0;
}

img.scale {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto !important;
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto !important;
  -ms-interpolation-mode: bicubic;
}

.text {
  flex: 1 0 60%;
  margin-right: 2rem;
  background: white;
}

.text * {
    width: 80%;
    margin: 0 auto;
}

h1 {
  font-size: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

h3 {
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.text > p,
.info-2__first > p,
.info-2__second {
  font-size: var(--font-size-md);
  line-height: 1.5rem;
  margin-top: 1.5rem;
}

.info-2__first,
.info-2__second {
  width: 50%;
  padding: 5rem 0;
}

.info-2__first {
  background: var(--color-darkdenim);
  color: var(--color-white);
}

.info-2__first img {
  width: 20%;
  height: auto;
}

.info-2__first span {
  color: var(--color-goldenrod);
}

.info-2__first li {
  padding: .5rem 0;
  line-height: 1.5rem;
  font-weight: 400;
}

/* .info-2__first li::before {
  content: '-';
  margin-right: .5rem;
  color: var(--color-goldenrod);
} */

.info-2__second {
  margin-top: 0;
  background: var(--color-black);
  color: var(--color-white);
}

/* .content img {
  width: 100%;
  margin-right: 2rem;
} */

.image-container {
  width: 100%;
  margin-right: 2rem;
}

.t-img {
  width: 100%;
  height: 25%;
  overflow: hidden;
}

.banner {
  width: inherit;
  height: auto;
  padding: 2rem;
  background: var(--color-goldenrod);
}

.banner h1 {
  font-size: 2.5rem;
  margin: 0;
}

.info-3__left {
  width: 50%;
  height: auto;
}

.info-3__left img {
  width: 100%;
}

.info-3__right {
  width: 20%;
}

.info-3__right p {
  margin-bottom: 2rem;
}

.selection img {
    width: 10%;
    margin-left: 0;
    margin-right: 1rem;
}

.selections * {
    margin: 3rem .2rem;
}

@media screen and (max-width: 1280px) {
  .info-2__first,
  .info-2__second {
    width: 100%;
    padding: 5rem 0;
  }

  .callout {
    top: 2rem;
  }

  .steps ul {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .hero-gallery {
    flex-direction: column;
    position: static;
  }

  .callout {
    position: static;
    width: 100%;
    padding: 1rem 2rem;
    margin: 2rem auto;
  }

  .eae-logo {
    width: 60%;
  }

  .eae-logo img {
    width: 100%;
  }

  .stat {
    position: static;
    padding: 0;
  }

  .callout {
    position: static;
  }

  .callout h1 {
    font-size: 2rem;
    color: #414141;
  }

  .g {
    flex-direction: column;
    text-align: center;
    margin: 2rem 0;
  }

  .g img {
    width: 40%;
    margin: 1rem auto;
  }

  .image-container {
    margin-right: 0;
  }
}

@media screen and (max-width: 798px) {
  .info-1, .info-2, .info-3 {
    flex-direction: column;

  }

  .info-3__right {
    width: 100%;
  }

  .info-grid {
    flex-direction: column;
  }

  .info-grid__image {
    width: 100%;
    height: 400px;
  }

  .info-grid__card {
    margin: 1.5rem 0;
  }

  .info-grid__text {
    margin: 1rem 1.5rem;
    text-align: center;
  }

  .info-grid__text h3 {
    display: none;
  }

  .standout-text {
    top: -5rem;
  }

  .standout-text h1 {
    font-size: 1.5em;
  }

  .steps ul {
    padding: 1rem;
  }

  .steps li {
    text-align: start;
    line-height: 1.2rem;
  }

}

@media screen and (max-width: 480px) {
  .selections {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .selections * {
    margin: .5rem .2rem;
  }

  /* .callout {
    margin-left: 0;
    margin-right: 0;
  } */

  .info-3 .text {
    width: 100%;
    margin: 2rem 0;
  }

  .info-3__left {
    width: 100%;
  }

  .text {
    width: 100%;
    margin-right: 0;
  }
}
</style>
