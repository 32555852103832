<template>
  <div class="leaderboard has-margin-top">
      
      <Leaderboard />
    </div>
</template>

<script>
import Leaderboard from '../components/Leaderboard';

export default {
    name: 'Game',
    title: 'Enjoy All Elements - Leaderboard',
    components: {
        Leaderboard
    },
    mounted () {
        window.scrollTo(0, 0);
    }
}
</script>

<style scoped>
.leaderboard {
    margin-top: 4rem;
}
</style>