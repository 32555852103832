<template>
  <section>
    <b-field 
        label="Email" 
        :label-position="labelPosition"
        
    >
        <b-input
            v-model="email"
            type="email"
            size="is-medium"
        >
        </b-input>
    </b-field>
    <b-field 
        label="Username"
        :label-position="labelPosition"
    >
        <b-input 
            v-model="username"
            type="string"
            size="is-medium"
        >
        </b-input>
    </b-field>
    <b-field 
        label="Hours"
        :label-position="labelPosition"
    >
        <b-input 
            v-model="hours"
            type="number"
            size="is-medium"
        >
        </b-input>
    </b-field>
    <b-field>
        <b-checkbox v-model="check">Yes, send me a weekly email reminder to log my hours</b-checkbox>
    </b-field>
    <b-button id="btn" class="has-margin-bottom" @click='onClick' size="is-medium" expanded>Submit</b-button>
    <p class="text-center">*An external window will open upon submit. Please allow pop-ups. We promise not to hurt you.</p>
  </section>
</template>

<script>
// import axios from 'axios';

export default {
  name: "Submission",
  data() {
    return {
        labelPosition: null,
        email: '',
        username: '',
        hours: '',
        check: false,        
    };
  },
  methods: {
      onClick() {
        let dataObj = {
            email: this.email,
            username: this.username,
            hours: this.hours,
            check: this.check,
        }

        console.log(dataObj.check);

        let jfURLParams = `https://form.jotform.com/211056275972054?email=${dataObj.email}&nickname=${dataObj.username}&hours=${dataObj.hours}&q14_sendYou[]=${dataObj.check}`;
        let openW = window.open(jfURLParams, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        openW.focus();

        setTimeout(function() {
            this.$router.push({ path: '/game' })
        }, 5000);
          
      },

      isDisabled() {
          if (this.email === '' && this.name === '' && this.hours === '') {
              return false;
          } else {
              return true;
          }
      },

      hasErrors() {
          return true;
      }
  }
};
</script>

<style scoped>
b-input {
  margin-bottom: 2rem;
}
</style>